//  mobile add no scroll class
const htmlTag = document.getElementsByTagName('html')[0];
const mobileMenuBtn = document.getElementsByClassName('mobile-menu')[0];

if (mobileMenuBtn) {
    mobileMenuBtn.addEventListener('click', function handleClick() {

        if (!htmlTag.classList.contains('no-scroll')) {
            htmlTag.classList.add('no-scroll');
        } else {
            htmlTag.classList.remove('no-scroll');
        }
    });
}


if (document.getElementById('catalogue')) {

    const filterSort = document.querySelector('.filters__sort');
    const sortBtn = document.querySelector('.filters__sort span');
    const input = document.getElementsByName("search")[0];
    // let sortMenu = document.querySelector('.filters__sort ul');
    sortBtn.addEventListener('click', function handleClick() {
        if (!filterSort.classList.contains('open__sort-menu')) {
            filterSort.classList.add('open__sort-menu');
        } else {
            filterSort.classList.remove('open__sort-menu');
        }
    });

    //  close sort menu
    window.onclick = function (event) {
        if (event.target.parentElement !== filterSort) {
            filterSort.classList.remove('open__sort-menu');
        }
    }

    //  search and sort
    let options = {
        valueNames: ['brand__name', { name: 'min', attr: 'data-min-price-tag' }, { name: 'max', attr: 'data-max-price-tag' },]
    };

    let list = new List('catalogue', options);

    let brandsBtn = document.querySelector('.search-brands');


    //enter key search
    // input.addEventListener("keyup", function(event) {
    //   if (event.key === "Enter") {
    //     let searchText = document.querySelector(".search-input").value;
    //     list.search(searchText);
    //   }
    // });

    //   //btn click search
    // brandsBtn.addEventListener('click', function handleClick(e) {
    //     let searchText = document.querySelector(".search-input").value;
    //     list.search(searchText);
    // });

}



if (document.getElementById('contact-form')) {
    $("#contact-form").validate();

    var accept = $("#accept");
    var inital = accept.is(":checked");
    var btn_disabled = $("#contact-form").find("input[type=submit]").attr("disabled", !inital);

    accept.click(function () {
        btn_disabled.attr("disabled", !this.checked);
    });
}


if (document.querySelector('input[name="send-it__date"]')) {

    const elem = document.querySelector('input[name="send-it__date"]');
    const minDate = document.querySelector('input[name="send-it__date"]').getAttribute('data-mindate')
    const datepicker = new Datepicker(elem, {
        format: 'dd MM yyyy',
        minDate: minDate,
        prevArrow: '<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11L1 6L6 1" stroke="#111827" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        nextArrow: '<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L1 11" stroke="#111827" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    });

    datepicker.pickerElement.addEventListener('click', (ev) => {
        console.log(ev.target.getAttribute('data-date'));
    });




}

if (document.querySelector('.swiper-upload')) {

    const swiper = new Swiper('.swiper-upload', {
        // Navigation arrows

        slidesPerView: "auto",
        spaceBetween: 6,
        slidesPerGroup: 2,
        grid: {
            rows: 3,
        },
        freeMode: true,
        breakpoints: {
            681: {
                slidesPerView: 3,
                spaceBetween: 6,
                slidesPerGroup: 2,
                freeMode: false,
                grid: {
                    rows: 3,
                },
            },


        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });

}
if (document.querySelector('.swiper-preview')) {
    const swiper2 = new Swiper('.swiper-preview', {

        navigation: {
            nextEl: '.swiper-preview-button-next',
            prevEl: '.swiper-preview-button-prev',
        },

    });
}

if (document.querySelector('.swiper-gif')) {
    const swiperGif = new Swiper('.swiper-gif', {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 2,
        grid: {
            rows: 2,
        },
        freeMode: true,
        navigation: {
            nextEl: '.swiper-gif-button-next',
            prevEl: '.swiper-gif-button-prev',
        },

    });
}


let selectAllOrders = document.querySelector('input[name=select_all]');
if (selectAllOrders) {
    selectAllOrders.addEventListener('click', function handleClick(e) {
        document.querySelectorAll('input[name^=order]').forEach((element) => {
            element.checked = selectAllOrders.checked;
        })
    });

}




if (document.getElementById('myorders')) {
    //search and sort
    let options = {
        valueNames: ['order_number', 'order_date', 'order_quantity', 'order_total', 'order_status'],
        page: 8,
        pagination: [{
            name: "pagination",
            paginationClass: "pagination",
            item: "<li><span class='page'></span></li>",
        }]

    };

    let list = new List('myorders', options);

    let brandsBtn = document.querySelector('.search-orders');
    brandsBtn.addEventListener('click', function handleClick(e) {
        let searchText = document.querySelector(".search-input").value;
        list.search(searchText);
    });

    list.on('updated', function () {
        document.querySelectorAll('input[name^=order]').forEach((element) => {
            if (element.checked == false) {
                selectAllOrders.checked = false;

            } else {
                selectAllOrders.checked = true;
            }
        })
    });

    document.querySelector('.next').addEventListener('click', function (e) {
        if (document.querySelector('.pagination .active').nextElementSibling != null) {
            document.querySelector('.pagination .active').nextElementSibling.children[0].click();
        }

    });
    document.querySelector('.prev').addEventListener('click', function (e) {
        if (document.querySelector('.pagination .active').previousElementSibling != null) {
            document.querySelector('.pagination .active').previousElementSibling.children[0].click();
        }
    });

}


if (document.querySelector('.my-balance')) {
    let closeBtn = document.querySelector('.balance svg:last-of-type');
    closeBtn.addEventListener('click', function (e) {
        document.querySelector('.balance').style.display = 'none';
    });
}


if (document.querySelector('.recipients__phone input')) {
    let inputPhones = document.querySelectorAll('.recipients__phone input');
    inputPhones.forEach(element => {
        element.addEventListener('input', function (e) {
            e.target.defaultValue = e.target.value;

        });
    });
}



if (document.getElementById('loader')) {
    // htmlTag.classList.add('no-scroll');
    let promise = new Promise(function (resolve, reject) {
        setTimeout(() => {
            let loader = document.getElementById('loader');
            loader.style = 'opacity:0; visibility: hidden;';
            // htmlTag.classList.remove('no-scroll');
            resolve();
        }, 1000);
    })
    promise.then(function () {
        setTimeout(() => {
            document.getElementById('loader').style = "display:none;";
        }, 500);
    });

}



if (document.querySelector('.post-content')) {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
}
